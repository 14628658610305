export const Logo = () => {
 return (
  <a
   className="rounded-lg py-2 px-3 flex flex-row font-bold logo w-min h-min bg-neutral-color"
   href="/"
  >
   <span className="">Type</span>
   <span className="">Racing</span>
   <span className="pulsing-gradient-text">.io</span>
  </a>
 );
};
